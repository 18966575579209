.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
  .MuiAutocomplete-input {
  border: none;
}

.auto-complete-input {
  width: 18rem;
  margin: 5px;
  padding: 2px;
}
.select-hotel-cont-div {
  width: 100%;
  margin: auto;
}
.select-auto-options {
  width: 90%;
  border: 1px solid red;
  /* margin: auto; */
}
.auto-search-hotel-name {
  border: 1px solid #cecece;
  padding: 4px 30px;
  border-radius: 4px;
  color: #ffffff;
  font-size: 20px;
  line-height: 28px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  margin: auto;
}

@media (max-width: 650px) {
  .auto-complete-input {
    width: auto;
    margin: 20px 6px;
    padding: 0px;
    background-color: #fff;
    border-radius: 4px;
  }
  .auto-search-hotel-name {
    padding: 10px 25px;
    font-size: 20px;
    margin-top: 75px;
    border: 1px solid #e2e2e2;
    color: #ffffff;
  }
}
