/* .hero-btn {
    background-color: blueviolet;
} */
/* .hero-btn:hover {
    background-color: aqua;
} */

.hero-btn {
  padding: 5px 18px;
  border: none;
  font-size: 12px;
  color: #fff;
  background: var(--button-color);
  background: rgb(0, 0, 0);
  border-radius: 4px;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
}
.hero-btn:hover {
  /* color: rgb(25, 118, 210); */

  /* background: rgb(255, 255, 255); */
}

@media (max-width: 650px) {
  .hero-btn {
    padding: 12px 0px;
    border: none;
    font-size: 16px;
    color: #fff;
    width: 100%;
    margin: auto;
    border-radius: 4px;
    margin-top: 30px;
  }
}
