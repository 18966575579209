.main-input-container {
    margin: 5px;
    font-size: 16px;
    font-weight: 500;
    

}



.main-input-container #dropdown{
    /* color: #ffffff; */
    padding: 4px;
    border: 1px solid transparent;
    /* border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent; */
    cursor: pointer;
    /* user-select: none; */
    /* background-color: DodgerBlue; */
    /* background: #0d9488; */

   
}

/* #dropdown:after {
    position: absolute;
    content: "";
    top: 14px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: #fff transparent transparent transparent;
  } */

  #dropdown-arrow-active:after {
    border-color: transparent transparent #fff transparent;
    top: 7px;
  }

  /* #dropdown value {
    position: absolute;
    background-color: DodgerBlue;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
  } */



@media (max-width: 650px) {
    .mobile-select{
      width: -webkit-fill-available;
      text-align: center;
      font-size: 15px;
      font-weight: 500;
  }

  .main-input-container {
    margin: 5px;
    font-size: 16px;
    font-weight: 500;
    padding: 2px;
}

.main-input-container p{
  padding: 10px;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 1px;

}

#dropdown{
  height:40px;
  overflow-y:auto;
    width:250px;
}
.optin{
  overflow-y:scroll;
}

}