
.mobile-dropdown-select{
    font-size: 18px;
    width: 95%;
    padding: 10px 0;
    margin:20px  auto;
    text-align: center;
}

.mobile-dropdown-select option{
    font-size: 14px;
    white-space: pre-line;
}