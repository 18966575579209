* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.calendarWrap {
  /* display: inline-block; */
  position: relative;
  /* border: 1px solid red; */
  padding: 4px;
  padding-right: 18px;
  border-right: 2px solid rgb(233, 227, 227);
}

.calendarElement {
  position: absolute;
  left: 70%;
  transform: translateX(-50%);
  top: 3.5rem;
  border: 1px solid #ccc;
  z-index: 999;
}

.rdrDefinedRangesWrapper {
  display: none;
}

.rdrMonth {
  width: 24.667em;
}
.calendar-popup {
  cursor: pointer;
  /* background: black;
  color: #fff;
  padding: 3px 6px;
  border-radius: 4px; */
}
.calendar-popup:hover {
  /* cursor: pointer;
  background: rgb(255, 255, 255);
  color: #000000;

  border-radius: 4px; */
}

@media (max-width: 650px) {
  .calendarWrap {
    border: none;
    border: none;
    color: white;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .calendarElement {
    border: none;
    top: 4.5rem;
    left: 50%;
  }

  .rdrCalendarWrapper {
    color: #000000;
    font-size: 14px;
    width: 80%;
    margin: auto;
    height: 60vh;
  }
  .rdrMonth {
    width: 27.667em;
  }
}
