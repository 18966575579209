.mobile-patch-main {
  /* width: 400px; */
  height: 100vh;
  /* background: var(--background-color); */
  background-color: #474391;

  display: flex;
  flex-direction: column;
  position: sticky;
  /* top: 20%; */
  transition: 1s ease-out;
}

.mobile-patch-main .close-icon {
  display: flex;
  justify-content: end;
  margin: 10px;
}

.mobile-button-main-comp {
  width: 100%;
  position: absolute;
  /* top: 93.3vh; */
  /* border: 1px solid red; */
  background: var(--button-color);
}
.mobile-button-main-comp button {
  background: var(--button-color);
  /* padding: 8px 80px; */
  border: none;
  padding: 8px 0 8px 0;
  color: #fff;
  font-size: 18px;
}
.transition-top {
  transform: translateY(100%);
}
.transition-active {
  transition: transform 0.5s ease-in-out;
  transform: translateY(0);
}

.mobile-select-mob-view {
  padding: 0px 16px;
  font-size: 16px;
}
.select-p-mobile-view {
  font-size: 12px;
  font-weight: 500;
  margin: 0;
}

@media (max-width: 650px) {
  .mobile-patch-main {
    height: 100vh;
    /* background: var(--background-color); */
    width: 100%;
    background: #474391;
    display: flex;
    flex-direction: column;
    /* position: sticky; */
    /* top: 20%; */
    transition: 1s ease-out;
    position: fixed;
    bottom: 0;
    z-index: 99999999;
  }

  .mobile-button-main-comp {
    background: var(--button-color);
    position: fixed;
    /* top: 93.3vh; */
    width: 100%;
    bottom: 0;
  }

  .mobile-select-mob-view {
    padding: 8px 60px;
  }
  .select-p-mobile-view {
    color: #fff;
    font-size: 18px;
  }
  .count-main-cont {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 10px auto;
  }
  .mobile-select-mob-view {
    padding: 4px 22px;
    font-size: 20px;
    text-align: center;
  }
  .mobile-patch-main .close-icon {
   
    margin: 10px;
    margin-top: 70px;
  }
}
